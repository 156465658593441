import React from 'react';
import Helmet from 'react-helmet';
import { RouteComponentProps } from '@reach/router';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Link from '@nib-components/link';
import Heading from '@nib-components/heading';
import Container from '@nib-components/container';
import Copy, { Bold } from '@nib-components/copy';
import Accordion from '@nib-components/accordion';
import { PrimaryButton } from '@nib-components/button';
import { Stack, Box, Section } from '@nib/layout';
import { colorTrueGreen } from '@nib-components/theme';

import HeroPanel from '../components/HeroPanel';

import Layout from '../components/Layout';
import metrics from '../metrics';

import desktop from '../img/ngatiwhatuaorakei/hero/desktop.jpg';
import desktop2x from '../img/ngatiwhatuaorakei/hero/desktop@2x.jpg';
import mobile from '../img/ngatiwhatuaorakei/hero/mobile.jpg';
import mobile2x from '../img/ngatiwhatuaorakei/hero/mobile@2x.jpg';
import tablet from '../img/ngatiwhatuaorakei/hero/tablet.jpg';
import tablet2x from '../img/ngatiwhatuaorakei/hero/tablet@2x.jpg';

import insuranceOverview from '../img/ngatiwhatuaorakei/insurance-overview.jpeg';
import memberCard from '../img/ngatiwhatuaorakei/member-card.jpg';

import { BackgroundImageWrapper, ContentWrapper, Wrapper } from '../components/styledComponents';
import { urlConstants } from '../constructs/constants';
import { ContentfulDocument } from '../constructs/models';

export const NwoPageQuery = graphql`
  query NwoPageQuery {
    policy: allContentfulDocument(filter: { name: { eq: "NWO Policy Document" } }, limit: 1) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
  }
`;

const title = 'Ngati Whatua Health Insurance';
const metaDescription =
  'The Ngāti Whātua Ōrākei Health Insurance, which is being provided ' +
  'in partnership with nib, will be available to all Ngāti Whātua Ōrākei hapū members.';

interface GraphQLNwoDocumentNode {
  edges: ContentfulNwoDocumentEdgeShape[];
}
interface ContentfulNwoDocumentEdgeShape {
  node: {
    document: ContentfulDocument;
  };
}

interface NwoDocumentProps {
  data: {
    brochure: GraphQLNwoDocumentNode;
    policy: GraphQLNwoDocumentNode;
  };
}

interface NWOProps extends RouteComponentProps, NwoDocumentProps {}

const NWO = (props: NWOProps): JSX.Element => {
  const policyDocument = props?.data?.policy?.edges?.[0]?.node?.document;

  return (
    <Layout>
      <div>
        <Helmet>
          <title>{title} | nib</title>
          <meta name="description" content={metaDescription} />
        </Helmet>
        <HeroPanelSection />
        <InsuranceOverviewSection />
        <WhatThePlanIncludesSection policyDocument={policyDocument} />
        <NibMemberCardSection />
        <FindAProviderSection />
      </div>
    </Layout>
  );
};

const HeroPanelSection = (): JSX.Element => {
  return (
    <HeroPanel
      title="Benefits of Ngāti Whātua Ōrākei Health Insurance"
      subTitle={<PrimaryButton href="/ngatiwhatuaorakei/enrol">Enrol now</PrimaryButton>}
      variation="condensed"
      images={{
        desktop: desktop,
        desktop2x: desktop2x,
        mobile: mobile,
        mobile2x: mobile2x,
        tablet: tablet,
        tablet2x: tablet2x,
      }}
    />
  );
};

const InsuranceOverviewSection = (): JSX.Element => (
  <Section>
    <Wrapper>
      <BackgroundImageWrapper backgroundImage={insuranceOverview} backgroundPosition="top" />
      <ContentWrapper isOnRight={true}>
        <Stack space={{ xs: 2, md: 4 }}>
          <Box marginBottom={4}>
            <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
              Ngāti Whātua Ōrākei health insurance overview
            </Heading>
          </Box>
          <Copy measure={false}>
            Ngāti Whātua Ōrākei is committed to improve the wellbeing and health of ngā uri o
            Tuperiri. As part of its overarching Toi Ora programme, Ngāti Whātua Ōrākei is providing
            a universal health insurance scheme for its members.
          </Copy>
          <Copy measure={false}>
            The Ngāti Whātua Ōrākei Health Insurance, which is being provided in partnership with
            nib, will be available to all registered Ngāti Whātua Ōrākei hapū members.
          </Copy>
          <Copy measure={false}>
            Ngāti Whātua Ōrākei will provide this plan to the level of Ngāti Whātua Ōrākei Health
            Insurance Base plan with the Specialist and Everyday options. You can include additional
            cover and/or non-member whānau at your own cost.
          </Copy>
        </Stack>
      </ContentWrapper>
    </Wrapper>
  </Section>
);

interface WhatThePlanIncludesSectionProps {
  policyDocument: ContentfulDocument;
}
const WhatThePlanIncludesSection = ({
  policyDocument,
}: WhatThePlanIncludesSectionProps): JSX.Element => (
  <Section background="sageGreen60">
    <Stack space={4}>
      <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h3">
        What the plan includes
      </Heading>
      <Copy measure={false}>
        The Ngāti Whātua Ōrākei Health Insurance Base plan with the Specialist and Everyday options
        includes: <sup>1</sup>
      </Copy>
      <Copy measure={false} component="div">
        <ul>
          <Stack space={2}>
            <li>Up to $300,000 per person per year for private surgical hospitalisation</li>
            <li>
              Up to $200,000 per person per year for private non-surgical hospitalisation, including
              cancer treatment
            </li>
            <li>Specialist consultations, even when unrelated to hospitalisation</li>
            <li>
              Counsellor, psychiatrist and psychologist consultations from an approved provider, up
              to $2,000 per policy year
            </li>
            <li>GP visits up to $350 per person per year</li>
            <li>Dental treatments and basic extractions up to $1,000 per person per year</li>
            <li>Glasses and contact lenses up to $250 per person per year</li>
            <li>Rongoā Māori treatment up to $500 per person per year</li>
          </Stack>
        </ul>
      </Copy>
      <Copy measure={false}>
        <sup>1</sup> This overview is an outline of the main features and benefits of the Ngāti
        Whātua Ōrākei Health Insurance policy. For a full explanation of the benefits and
        exclusions, and more legal bits, see the Ngāti Whātua Ōrākei Health Insurance{' '}
        <Link href={policyDocument?.file?.url} target="_blank">
          policy document
        </Link>
        .
      </Copy>
    </Stack>
  </Section>
);

const NibMemberCardSection = (): JSX.Element => (
  <Section>
    <Stack space={7}>
      <MemberCardContent />
      <MemberCardAccordionContent />
    </Stack>
  </Section>
);

const MemberCardContent = (): JSX.Element => {
  return (
    <Wrapper>
      <BackgroundImageWrapper backgroundImage={memberCard} />
      <ContentWrapper isOnRight={true}>
        <Stack space={{ xs: 2, md: 4 }}>
          <Box marginBottom={4}>
            <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h3">
              nib Member Card
            </Heading>
          </Box>
          <Copy measure={false}>
            At nib, we want to make it easy for you and your whānau to get the right healthcare you
            need when you need it.
          </Copy>
          <Copy measure={false}>
            Take your member card along with you to your health appointments to let your healthcare
            provider know you’re insured with nib.
          </Copy>
          <Copy measure={false}>
            They’ll help you find a range of healthcare options that best suit the needs of you and
            your whānau.
          </Copy>
        </Stack>
      </ContentWrapper>
    </Wrapper>
  );
};

const StyledAccordionItem = styled(Accordion.Item)`
  h4 {
    color: ${colorTrueGreen};
  }
  p {
    color: ${colorTrueGreen};
  }
`;

const MemberCardAccordionContent = (): JSX.Element => {
  return (
    <Container>
      <Stack space={4}>
        <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h3">
          nib Member Card FAQs
        </Heading>
        <Accordion>
          <StyledAccordionItem
            title="Who is eligible to have an nib member card?"
            titleComponent="p"
          >
            <Copy measure={false}>
              The card is available to Ngāti Whātua Ōrākei members who are enrolled under the nib
              Ngāti Whātua Ōrākei Health Insurance Plan.
            </Copy>
          </StyledAccordionItem>
          <StyledAccordionItem title="How do I use my nib member card?" titleComponent="p">
            <Stack space={4}>
              <Copy measure={false}>
                Your card lists the members of your whānau covered under your nib Ngāti Whātua
                Ōrākei Health Insurance policy and your policy number.
              </Copy>
              <Copy measure={false}>
                Take it to your appointments to let your healthcare provider know you’re insured
                with nib. They can then help you explore a wider range of healthcare services.
              </Copy>
            </Stack>
          </StyledAccordionItem>
          <StyledAccordionItem
            title="How do I request a pre-approval or make a claim?"
            titleComponent="p"
          >
            <Stack space={4}>
              <Copy measure={false}>
                At <Link href={urlConstants.mynib}>www.nib.co.nz/mynib</Link> or through the my nib
                mobile app. Download the app through{' '}
                <Link href={urlConstants.appleAppStore}>Apple’s App Store</Link> if you have an
                iPhone, or through <Link href={urlConstants.googlePlayStore}>Google Play</Link> if
                you have an Android phone.
              </Copy>
              <Copy measure={false}>
                If you’re using an{' '}
                <Link href={urlConstants.findAProvider}>nib First Choice network</Link> healthcare
                provider, they can request a pre-approval or make a claim for you.
              </Copy>
              <Copy measure={false}>
                The nib First Choice network is a growing community of registered specialists and
                hospitals who provide hassle-free claims by doing the paperwork for you, so you can
                focus on getting well.
              </Copy>
              <Copy measure={false}>
                Find a First Choice healthcare provider at{' '}
                <Link href={urlConstants.findAProvider}>www.nib.co.nz/find-a-provider</Link>
              </Copy>
              <Copy measure={false}>
                Please note that your member card is <Bold>not a payment card</Bold>.
              </Copy>
            </Stack>
          </StyledAccordionItem>
          <StyledAccordionItem
            title="How can I request a new or replacement nib member card?"
            titleComponent="p"
          >
            <Stack space={4}>
              <Copy measure={false}>
                Email{' '}
                <Link href="mailto:ngatiwhatuaorakei@nib.co.nz">ngatiwhatuaorakei@nib.co.nz</Link>.
                A new card will be posted to the address listed on your nib health insurance policy
                at no cost.
              </Copy>
              <Copy measure={false}>
                It’s important that the postal address we have for you is correct. You can update
                this at <Link href={urlConstants.mynib}>www.nib.co.nz/mynib</Link>, by emailing{' '}
                <Link href="mailto:ngatiwhatuaorakei@nib.co.nz">ngatiwhatuaorakei@nib.co.nz</Link>{' '}
                or phoning 0800 NIB NWO.
              </Copy>
            </Stack>
          </StyledAccordionItem>
          <StyledAccordionItem
            title="I’m not enrolled under the nib Ngāti Whātua Ōrākei Health Insurance Plan, but I am a Ngāti Whātua Ōrākei hapū member. How do I enrol with nib?"
            titleComponent="p"
          >
            <Copy measure={false}>
              Visit{' '}
              <Link href="/ngatiwhatuaorakei/enrol">www.nib.co.nz/ngatiwhatuaorakei/enrol</Link> and
              complete all relevant sections of the enrolment form.
            </Copy>
          </StyledAccordionItem>
        </Accordion>
      </Stack>
    </Container>
  );
};

const FindAProviderSection = (): JSX.Element => (
  <Section background="warmWhite60">
    <Stack space={4}>
      <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="p">
        Find a provider
      </Heading>
      <Copy measure={false}>
        Look for a GP, physiotherapist, or a dentist in your area with our First Choice Network
        search tool.
      </Copy>
      <PrimaryButton href={urlConstants.findAProvider}>Find a Provider</PrimaryButton>
    </Stack>
  </Section>
);

export default metrics({ pageName: 'nwo' })(NWO);
